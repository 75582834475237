import { resetClaims } from 'feature/claims/claimsSlice';
import {
	handleChange,
	resetFiltersClaims,
	setSearch,
} from 'feature/form/formSlice';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AppDispatch, RootState } from 'store/store';
import { useTranslate } from 'stylesHooks';
import { claimStatusIds } from 'types/Claims';
import {
	RESET_SELECTED_ROUTES,
	SERVICES_SHORT_TRANSLATION_KEY,
	TRANSLATION_KEYS,
} from 'utils/constants';

export const useGetClaimStatusProperty = () => {
	const { claimsStatuses } = useSelector((store: RootState) => store.form);

	return (statusId: claimStatusIds) =>
		claimsStatuses.find(el => el.id === statusId) || {
			id: 0,
			name: '',
			label: '',
			groupCode: '',
			groupLabel: '',
		};
};
export const useGetServiceTypeInitials = () => {
	const { t: tDBLabels } = useTranslate(TRANSLATION_KEYS.DB_LABELS);

	const { servicesMap } = useSelector((state: RootState) => state.claim);

	const getInitialLabel = useCallback(
		(serviceType: string | undefined) => {
			if (!serviceType) {
				return '-';
			}

			const matching = servicesMap.find(
				service =>
					service.code.toLowerCase().replace('-', '') ===
					serviceType.toLowerCase().replace(' ', ''),
			);

			if (!matching) {
				return '-';
			}

			return tDBLabels(`${matching.label}.${SERVICES_SHORT_TRANSLATION_KEY}`);
		},
		[servicesMap, tDBLabels],
	);

	return {
		getInitialLabel,
	};
};

export const useResetPage = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { pathname: currentPath } = useLocation();

	const sectionPage = currentPath.split('/')[1];

	useEffect(() => {
		const isViewClaims = currentPath.startsWith(
			RESET_SELECTED_ROUTES.VIEW_CLAIMS,
		);

		if (isViewClaims) {
			return;
		}

		dispatch(resetFiltersClaims());
		dispatch(resetClaims());
	}, [currentPath, dispatch]);

	useEffect(() => {
		dispatch(
			handleChange({
				name: 'dateOfService',
				value: null,
			}),
		);

		dispatch(setSearch(false));
	}, [dispatch, sectionPage]);
};
