import { FC, Fragment } from 'react';
import { BoxOverflow, IconButton, Typography } from 'styles';

import { ReactComponent as CloseDialog } from 'assett/icons/close_dialog_icon.svg';
import useDialogConfirm from 'hooks/DialogConfirm.hook';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import Button from 'styles/button/Button';
import Dialog from 'styles/dialog/Dialog';
import Stack from 'styles/stack/Stack';
import { useTranslate } from 'stylesHooks';
import { TRANSLATION_KEYS } from 'utils/constants';
import packageJson from '../../../../package.json';

interface IData {
	title: string;
	content: string | null;
	caption?: string;
}

const DialogConfirm: FC = () => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { t: tErrorLabels } = useTranslate(`${TRANSLATION_KEYS.ERRORS}`);
	const dialogConfirm = useDialogConfirm();

	const {
		open,
		title,
		content,
		caption,
		messageCode,
		labelCancel,
		labelConfirm,
		variantButtonCancel,
		multi,
		data,
		buttonClose,
	} = useSelector((store: RootState) => store.hooks.dialog);

	const { type } = useSelector((store: RootState) => store.hooks.dialog);

	const contentMessage =
		content && typeof content === 'string'
			? tClientLabels(content)
			: messageCode && tErrorLabels(`${messageCode}`) // these are the error messages
			? tErrorLabels(`${messageCode}`)
			: tClientLabels(`${TRANSLATION_KEYS.DIALOG}.confirm.error.content`);

	return (
		<Dialog
			type={type}
			open={open}
			close={
				buttonClose ? (
					<IconButton onClick={() => dialogConfirm.close()}>
						<CloseDialog />
					</IconButton>
				) : null
			}
			variant="confirm"
			title={tClientLabels(`${title}`)}
			events={!!labelCancel || !!labelConfirm}
			actions={
				<Fragment>
					{labelCancel ? (
						<Button
							variant={variantButtonCancel}
							// onClick={() => dialogConfirm.cancel()}
						>
							{tClientLabels(`${labelCancel}`)}
						</Button>
					) : null}
					{labelConfirm ? (
						<Button variant="contained" onClick={() => dialogConfirm.confirm()}>
							{tClientLabels(`${labelConfirm}`)}
						</Button>
					) : null}
				</Fragment>
			}
		>
			{typeof content !== 'string' ? (
				<Typography variant="body">{content}</Typography>
			) : !multi ? (
				<Stack gap={'20px'}>
					<Typography variant="body">
						{type === 'version'
							? `${contentMessage} ${packageJson.version}`
							: contentMessage}
					</Typography>
					{caption && (
						<Typography variant="caption" color={theme => theme.palette.grey1.main}>
							{tClientLabels(`${caption}`)}
						</Typography>
					)}
				</Stack>
			) : (
				<BoxOverflow variant="y" sx={{ gap: '20px' }}>
					{data.map((e: IData, index) => {
						return (
							<Stack key={index} gap={'16px'}>
								<Typography variant="title6">{e.title}</Typography>
								<Typography variant="body">{e.content}</Typography>
								{caption && (
									<Typography
										variant="caption"
										color={theme => theme.palette.grey1.main}
									>
										{e.caption}
									</Typography>
								)}
							</Stack>
						);
					})}
				</BoxOverflow>
			)}
		</Dialog>
	);
};

export default DialogConfirm;
