import {
	AnyAction,
	createSlice,
	isAnyOf,
	isRejected,
	isRejectedWithValue,
} from '@reduxjs/toolkit';

import { ButtonPropsVariantOverrides } from '@mui/material/Button';
import { OverridableStringUnion } from '@mui/types';
import {
	CHECK_CONNECTION_ERROR,
	ERROR_MESSAGE,
	TOKEN_EXPIRED,
} from 'types/dialogTypes';

interface IState {
	type: string;
	buttonClose: boolean;
	open: boolean;
	title: string;
	content: string;
	caption: string;
	messageCode: string;
	labelCancel: string;
	labelConfirm: string;
	events: boolean;
	multi: boolean;
	data: { title: string; content: string }[];
	variantButtonCancel: OverridableStringUnion<
		'text' | 'outlined' | 'contained',
		ButtonPropsVariantOverrides
	>;
}

const initialState: IState = {
	open: false,
	title: '',
	content: '',
	caption: '',
	messageCode: '', //this is an alternative to content. with this we need to extract the message from textsLocalesSlice
	labelCancel: '',
	labelConfirm: '',
	variantButtonCancel: 'outlined',
	events: true,
	multi: false,
	data: [],
	type: '',
	buttonClose: false,
};

const dialogSlice = createSlice({
	name: 'dialog',
	initialState,
	reducers: {
		setDialogOpen: (state, { payload }) => {
			state.open = payload;
		},
		setDialogEventCancel: state => {
			state.open = false;
			state.title = '';
			state.content = '';
			state.labelCancel = '';
			state.labelConfirm = '';
		},
		setDialogEventConfirm: state => {
			state.open = false;
			state.title = '';
			state.content = '';
			state.labelCancel = '';
			state.labelConfirm = '';
		},
		setInjectionState: (state, { payload }) => {
			state.title = payload.title;
			state.content = payload.content;
			state.caption = payload.caption;
			state.labelCancel = payload.labelCancel;
			state.labelConfirm = payload.labelConfirm;
			state.variantButtonCancel = payload.variantButtonCancel;
			state.multi = payload?.multi ? payload.multi : false;
			state.data = payload?.data ? payload.data : [];
			state.type = payload.type;
			state.buttonClose = payload.buttonClose;
		},
		resetDialogConfirm: () => initialState,
	},
	extraReducers: builder => {
		//manage errors popup
		builder.addMatcher(
			isAnyOf(isRejected, isRejectedWithValue),
			(state: IState, response: AnyAction) => {
				if (response.meta.aborted) {
					return;
				}

				const errorCode = response.payload?.data?.errorCode;
				switch (errorCode) {
					case '00005':
						return {
							...state,
							title: 'dialog.confirm.error.title',
							content: response.payload?.data?.message
								? response.payload?.data?.message
								: 'dialog.confirm.error.content',
							multi: false,
							buttonClose: true,
							open: true,
							type: TOKEN_EXPIRED,
							labelConfirm: 'commons.buttons.close',
						};
					default:
						switch (response.type) {
							case 'healthCheck/healthCheck/rejected':
								return {
									...state,
									open: true,
									title: 'dialog.confirm.noVpn.title',
									content: response.payload?.data?.message
										? response.payload?.data?.message
										: 'dialog.confirm.noVpn.content',
									caption: '',
									multi: false,
									buttonClose: false,
									labelCancel: '',
									variantButtonCancel: 'outlined',
									labelConfirm: 'commons.retry',
									type: CHECK_CONNECTION_ERROR,
								};

							default:
								return {
									...state,
									title: 'dialog.confirm.error.title',
									content: '',
									caption: '',
									messageCode: errorCode,
									multi: false,
									buttonClose: true,
									open: true,
									labelCancel: '',
									variantButtonCancel: 'outlined',
									type: ERROR_MESSAGE,
									labelConfirm: 'commons.buttons.close',
								};
						}
				}
			},
		);
	},
});

export const {
	setDialogOpen,
	setDialogEventCancel,
	setDialogEventConfirm,
	setInjectionState,
	resetDialogConfirm,
} = dialogSlice.actions;
export default dialogSlice.reducer;
