import { CompositeTypography } from 'styles';
import Paper from 'styles/paper/paper';
import Stack from 'styles/stack/Stack';
import { useTranslate } from 'stylesHooks';
import { IClaim } from 'types/Claim';
import { TRANSLATION_KEYS } from 'utils/constants';
import ClaimStatusBreadcrumbs from './ClaimStatusBreadcrumbs.component';

const ClaimMemberDetails = ({ claim }: { claim: IClaim }) => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { t: tDbLabels } = useTranslate(TRANSLATION_KEYS.DB_LABELS);

	return (
		<Paper
			elevation={0}
			sx={{
				p: 2.5,
				justifyContent: 'space-between',
				display: 'flex',
				borderRadius: 0.5,
				width: '100%',
			}}
		>
			<Stack flexGrow={1} rowGap={1.875}>
				<CompositeTypography
					label={tClientLabels('claim.labels.memberFullName')}
					value={claim?.memberFullName as string}
				/>
				<CompositeTypography
					label={tClientLabels('claim.labels.memberId')}
					value={claim?.memberId as string}
				/>
				<CompositeTypography
					label={tClientLabels('claim.labels.memberDesignation')}
					value={tDbLabels(claim?.memberDesignationLabel as string)}
				/>
			</Stack>
			<Stack flexGrow={0} width="auto" minWidth={'40%'} rowGap={1.875}>
				<CompositeTypography
					label={tClientLabels('claim.labels.planName')}
					value={claim?.planName as string}
				/>
				<CompositeTypography
					label={tClientLabels('claim.labels.memberProviderLocation')}
					value={claim?.memberProviderLocation as string}
				/>
				<CompositeTypography
					label={tClientLabels('claim.labels.status')}
					value={claim?.status.label as string}
					children={<ClaimStatusBreadcrumbs claimStatusId={claim.status.id} />}
				/>
			</Stack>
		</Paper>
	);
};

export default ClaimMemberDetails;
