export const TRANSLATION_KEYS = {
	ERRORS: 'errors',
	DIALOG: 'dialog',
	DB_LABELS: 'dbLabels',
	CLIENT_LABELS: 'clientLabels',
};
export const SERVICES_SHORT_TRANSLATION_KEY = 'short';
// for the moment we have special needs only on 'others'
export enum SERVICES_GROUPS {
	OTHERS = 'others',
}

export enum RESET_SELECTED_ROUTES {
	VIEW_CLAIMS = '/view-claims',
}
